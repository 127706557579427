
import { defineComponent, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import UserInfoService from "@/core/services/UserInfoService";

export default defineComponent({
  name: "user-profile",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Хэрэглэгчийн мэдээлэл");
    });
  },
  data() {
    return {
      loading: true,
      userdata: {
        name1: "",
        name2: "",
        phone: ""
      }
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loading = true;
      const a = UserInfoService.getInfo();
      await ApiService.get(`userinfo`, `?username=${a}`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.userdata = results.response;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
